// @import 'tailwindcss/base';
// @import 'tailwindcss/components';
// @import 'tailwindcss/utilities'; 
 
// @import '../node_modules/flowbite/dist/flowbite.min.css';


@tailwind base;
@tailwind components;
@tailwind utilities;  


@layer base {
  span {
    // @apply text-red-800 dark:text-yellow-300
    // dark:bg-gray-800; 
    font-family: "Sofia Pro", sans-serif; 
  } 

  .bg-custom-light {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }
  
  .bg-custom-dark {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }
}


.appBody{
  @apply h-full w-full bg-white dark:bg-gray-800;
}

//*** GOOGLE FONTS *****************************
.material-symbols-outlined {
  // to set an accettable fallback font
  font-family: 'Material Symbols Outlined', Arial, sans-serif; 
}  

//****  SCROLLBAR STYLES  ************************** 
// for Chrome and Safari
::-webkit-scrollbar {
  width: 8px; // for vertical scrollbars 
  height: 8px; // for horizontal scrollbars
}
::-webkit-scrollbar-thumb {
  background: #293749; // bg bar
}
 
::-webkit-scrollbar-thumb:rounded {
  border-radius: 4px; // opzional to round the corners
}
 
::-webkit-scrollbar-track {
  background: #9e9b9b; // bg track
}


//****  SLIDERs  ************************** 

// input[type=range]::-webkit-slider-thumb {
//    @apply w-20 h-8 bg-red-600  rounded-full cursor-pointer; 
// } 

  

// Firefox only 
html {
  scrollbar-width: thin;
  scrollbar-color: #293749; 
}


//? direttiva  WRAPPER Tooltip flowbite 
.tooltip{
  background-color: orangered;
  color: #fff;
  padding: 5px 10px; 
  z-index: 51;
  @apply bg-gray-500  border-3 border-yellow-500  ;
}

.tooltip-arrow {     
  z-index: 51;
}

.tooltip[data-popper-placement^='left'] > .tooltip-arrow {
  right: -6px; 
}
.tooltip[data-popper-placement^='right'] > .tooltip-arrow {
  left: -6px; 
}
.tooltip[data-popper-placement^='top'] > .tooltip-arrow {
  bottom: -6px; 
}
.tooltip[data-popper-placement^='bottom'] > .tooltip-arrow {
  top: -6px; 
}

//? --- DIRETTIVA CUSTOM TOOLTIP (dismissed) --- 
.ng-tooltip {
  width: max-content; 
  position: absolute;
  background-color: orangered;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 9999;

  @apply bg-gray-500 outline outline-2 outline-offset-2 outline-yellow-500;
} 

.ng-tooltip-show {
  opacity: 1;
}

.ng-tooltip::before {
  content: "";
  position: absolute;
  border-style: solid;
}

// TOP
.ng-tooltip-top::before {
  top: 112%;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-color: orange transparent transparent transparent;
}

// BOTTOM
.ng-tooltip-bottom::before {
  bottom: 113%;
  left: 50%;
  margin-left: -5px;
  border-width: 8px;
  border-color: transparent transparent orange transparent;
}

// LEFT
.ng-tooltip-left::before {
  left: 101%;
  top: 50%;
  margin-top: -7px;
  margin-left: 1px;
  border-width: 8px;
  border-color: transparent transparent transparent orange; 
}

// RIGHT
.ng-tooltip-right::before {
  right: 101%;
  top: 50%;
  margin-top: -7px;
  border-width: 8px;
  border-color: transparent  orange transparent transparent; 
}




//******* CENTRALIZED TAILWIND STYLES ************

//? ------ JOB MAP -----
.jobMap{
  @apply rounded-md border border-gray-500  h-screen-55 w-full ;      
}

.mapWarn{
  @apply text-red-600 font-bold text-4xl; 
}


.text-red{
  @apply text-red-600;
}

.bg-red{
  @apply bg-red-600;
}

//? ------ TABLE -----

.divide-gray-200>:not([hidden])~:not([hidden]) , .divide-y > :not([hidden]) ~ :not([hidden])   {
  
  @apply  border-y-gray-200 dark:border-y-gray-600;   

    tr.active{ 
      border-bottom-width: 4px!important; 
      border-top-width: 4px!important; 
      border-left-width: 4px!important; 
      border-right-width: 4px!important;  
      @apply bg-gray-300 dark:bg-black  border-black dark:border-white ; 
    }
}

table{
  @apply min-w-full divide-y divide-blue-800;
  thead { 
    @apply border-b border-gray-400 ;
    th{
      @apply p-2 text-left  text-xs lg:text-sm font-semibold text-gray-900 dark:text-white;
    }
  }

  tbody{
    // @apply  divide-y ; 

    // tr.active{
    //   border-top-width: 4px!important;
    //   border-bottom-width: 4px!important;
    //   @apply bg-gray-300 dark:bg-black  border-y-yellow-400 dark:border-y-red-600 ; 
    // }
    td {
      @apply  p-2 text-sm text-gray-900  dark:text-gray-200;
    }
  }
}

li.active{
  @apply   border-4  bg-white  dark:bg-black  border-black dark:border-white ; 
}

//? ------ FORM -----

  // input:focus {
  //   @apply  focus:ring-2 focus:ring-yellow-500 focus:outline-none focus:border-yellow-500;
  // }

  input:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  span.filterImage {
    @apply  h-8 w-8  font-bold text-gray-300 dark:text-gray-500 ;
    line-height: 1.9rem!important;
    font-size: 2.2rem!important;
  }

  span.enterImage {
    @apply  h-8 w-6  bg-gray-50 dark:bg-gray-600  font-bold text-gray-300 dark:text-gray-500 ;
    line-height: 1.9rem!important;
    font-size: 2.2rem!important;
  }

  label.blockLabel {
    @apply block mb-2 text-sm font-medium text-gray-900 dark:text-white;
  }

  input.actInputFld ,  select.actInputFld,  textarea.actInputFld  {
    @apply  border border-gray-300 dark:border-gray-500 text-gray-900 text-sm sm:text-base  rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full px-2 py-2.5 
    dark:bg-gray-600  bg-gray-50  dark:placeholder-gray-400 dark:text-white; 
  } 

  input[type = 'file'].actInputFileFld {
    // @apply h-[2.7rem] border border-gray-300 dark:border-gray-500 text-gray-900 text-base rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 
    // dark:bg-gray-600  bg-gray-50  dark:placeholder-gray-400 dark:text-white; 
    // line-height: 0.2rem!important;

    @apply block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 
      dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400;
  }

  input.actInputFldEnter   {
    @apply  bg-gray-50 dark:bg-gray-600  border border-gray-300 text-gray-900 text-sm rounded-lg 
    focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 px-2 
    dark:border-gray-500 dark:placeholder-gray-400 dark:text-white;
    // background-image: url('./assets//bg-img/csv.png');
  } 

  input.readonly, textarea.readonly {
    @apply  opacity-30;
  }

 



 

 
  input[type='checkbox'].actInputFld {
    @apply w-10 h-10 p-2.5 inline-block   text-sm rounded-lg border  focus:ring-gray-500 focus:border-gray-500  
        bg-gray-100  border-gray-300 text-gray-900
        dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white;

    &:checked:hover {
      @apply  bg-gray-100  border-gray-300 text-gray-900
              dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white;
    } 

    &:checked:focus {
      @apply  bg-gray-100  border-gray-300 text-gray-900
              dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white;
    } 
  
    &:checked {
      background-size: 3.55em 2.55em; 
      @apply bg-custom-light dark:bg-custom-dark;
    } 
  }
   
 

  input[type='radio'].actInputFld {
    @apply w-8 h-8 mr-2 p-1 inline-block rounded-full text-green-600 border border-gray-300 focus:ring-green-600 focus:ring-2 ;
    
    &:checked {
      background-size: 3.55em 2.55em;
    } 
  }

  .formError {
    @apply text-red-600 text-sm font-medium mt-1;
  }

  .formLiveResult {
    @apply text-blue-600 text-sm font-medium;
  }

  .formDisclaimer {
    @apply text-gray-400 text-sm font-medium;
  }

  .toggleRed {  @apply   peer-checked:bg-red-600  peer-focus:ring-red-800  }
  .toggleGreen { @apply  peer-checked:bg-green-600  peer-focus:ring-green-800 } 
  .togglePurple { @apply peer-checked:bg-purple-600  peer-focus:ring-purple-800  } 
  .toggleTeal { @apply  peer-checked:bg-teal-500  peer-focus:ring-teal-700 } 
  .toggleYellow { @apply peer-checked:bg-yellow-400  peer-focus:ring-yellow-800  } 
  .toggleOrange { @apply  peer-checked:bg-orange-400  peer-focus:ring-orange-800 }  


//? ------ LINK -----



//? ------  BUTTONS restyled -------------------------- 

  .baseBtn{
    @apply  p-[0.5rem]
            border-2   gap-x-2 items-center justify-center   rounded-lg   ;
    line-height: 1!important;

    span, span.ico {
      @apply   xl:text-2xl font-semibold ;
      vertical-align: middle;  
      line-height: 1!important;
    }

    span.text {
      @apply  ml-2 text-base  font-semibold ;
      vertical-align: middle; 
      line-height: 1!important; 
    }

    span.badge{
      @apply  flex items-center justify-center px-4 py-1 text-sm font-bold text-gray-800 bg-white dark:bg-gray-200 rounded-full;
    }
  } 

  .actBtnDownloadFiles {
    @apply pt-1 pb-1 px-1  xl:pt-1 xl:pb-2 xl:px-1  
    border-2 flex items-center justify-center rounded-lg 
    hover:bg-gray-200  dark:hover:bg-gray-500  border-gray-600  dark:border-gray-200 ; 
  }

  .csv{ 
    @apply h-6 w-6  ;
    background-image: url('./assets/bg-img/csv.png');  
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
  }

  .excel{ 
    @apply h-6 w-6   ;
    background-image: url('./assets/bg-img/xlsx.png');  
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
  }

  .pdf{ 
    @apply h-6 w-6  ;
    background-image: url('./assets/bg-img/pdf.png');  
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
  } 


  .downloadPdf {
    @apply p-1 xl:p-2  flex items-center gap-x-2 justify-center 
    border-2 flex whitespace-nowrap items-center justify-center rounded-lg 
    hover:bg-gray-200  dark:hover:bg-gray-500  border-gray-600  dark:border-gray-200 ; 
  }

  .actBtnCard {
     @apply actBtnTable ; 
  }

  .actBtnTable {
      @apply  baseBtn 
        text-gray-600 dark:text-gray-200 
        focus:bg-gray-200  dark:focus:bg-gray-500
        hover:bg-gray-200  dark:hover:bg-gray-500  
        border-gray-600  dark:border-gray-200 ; 
  }

  .actBtnTableRed {
    @apply baseBtn 
      text-red-400 hover:text-red-600
      hover:bg-gray-200  dark:hover:bg-gray-400  
      focus:bg-gray-200  dark:focus:bg-gray-400  
      border-red-400 ; 
  }


  .navigationButton{ 
      @apply baseBtn    
        focus:bg-gray-200  dark:focus:bg-gray-500
        hover:bg-gray-200  dark:hover:bg-gray-500  
        bg-blue-100 dark:bg-transparent 
        text-blue-800  dark:text-blue-400  
        border-blue-400 
        focus:bg-blue-200 dark:focus:bg-gray-500 ; 
  }

  a.docLinkButton{ 
      @apply baseBtn    
      flex items-center justify-center gap-x-2
      focus:bg-gray-200  dark:focus:bg-gray-500
      hover:bg-gray-200  dark:hover:bg-gray-500  
      bg-blue-100 dark:bg-transparent 
      text-blue-800  dark:text-blue-400  
      border-blue-400 
      focus:bg-blue-200 dark:focus:bg-gray-500 ; 
  }
 

  .invisibleButton{
    @apply  cursor-pointer text-transparent font-semibold 
      gap-x-2 items-center justify-center py-1 px-2 rounded-lg
      bg-transparent  border-2 border-transparent rounded-lg    ;
    span, span.ico {
      @apply   xl:text-2xl font-semibold;
      vertical-align: middle;  
    }
    span.text {
      @apply  ml-2 text-base ;
      vertical-align: middle;   
    } 
  }

  .loaderBtn { 
    @apply flex items-center justify-center gap-x-2 w-auto  
    bg-gray-700  text-gray-300  border-2  
    focus:outline-none 
    font-semibold rounded-lg   px-2 py-2 text-center  ;
    span, span.ico {
      @apply   xl:text-2xl font-semibold;
      line-height: 0.85;
      vertical-align: middle;  
    }
    span.text {
      @apply  text-base font-semibold;
      vertical-align: middle;   
    }
  }
   
  .disabledBtn{
    @apply cursor-not-allowed   w-auto float-right 
    bg-gray-200 text-gray-400   dark:bg-gray-400  dark:text-gray-500  focus:ring-4 focus:outline-none focus:ring-gray-300 
    font-semibold rounded-lg text-base px-3 py-2.5 text-center  dark:focus:ring-gray-800;
  }
  
  .enabledBtn { 
    @apply  text-gray-800  hover:bg-green-400 ring-4 bg-green-300  ring-green-500  w-auto float-right  focus:ring-4 
    focus:outline-none  font-semibold rounded-lg text-base px-3 py-1.75 text-center 
    focus:ring-green-500  dark:focus:ring-green-500;
  }

  .backBtn{
    @apply flex items-center justify-center gap-x-2 w-auto  
    bg-gray-700  text-gray-300   focus:ring-4 
    focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800
    font-semibold rounded-lg text-sm px-2 py-2.5 text-center  ;
  }

  .btn-tool {
    @apply cursor-pointer border-2 
    border-gray-400 bg-gray-300  hover:border-gray-800 hover:text-gray-800 dark:bg-gray-600 dark:hover:border-gray-200 dark:hover:text-gray-200 
    rounded-lg text-sm p-1 flex items-center justify-center;
    span {
      @apply text-gray-600 dark:text-gray-300   ;
    }
    &:disabled span {
      @apply text-gray-400 dark:text-gray-500 ;
    }
  }

  .btn-tool-map{
    @apply cursor-pointer text-gray-600 dark:text-gray-300  border-2 border-gray-400 bg-gray-300  
    hover:border-gray-800 hover:text-gray-800 dark:bg-gray-600 dark:hover:border-gray-200 dark:hover:text-gray-200 
    rounded-lg text-sm py-0 px-1 inline-flex items-center;
  }


  .anchorLink {
    @apply cursor-pointer underline first-letter:capitalize;
  }

  .anchorLinkMailto {
    @apply cursor-pointer underline first-letter:capitalize;
  }

  .actBtnSmall {
    @apply border-4 border-gray-600 flex p-1 text-center rounded-md text-sm font-normal hover:bg-gray-300  dark:hover:bg-gray-500   ;
    span.ico {
      @apply  text-gray-600  dark:text-gray-200  font-normal h-full  ;
    }
  }

  .actBtnSmallRed {
    @apply border-4 border-red-600 flex p-1 text-center rounded-md text-sm font-normal hover:bg-gray-300  dark:hover:bg-gray-500   ;
    span.ico {
      @apply  text-red-600  font-normal h-full  ;
    }
  }
 
  //-------------------------------------------
  

  

 

   

//? ------ TAB MENU -------- 

    .tab {
      @apply inline-flex p-1 border-b-2 border-transparent text-gray-500  dark:text-gray-300  hover:text-gray-600 hover:border-red-600 dark:hover:text-gray-300 ;
      svg{ 
        fill: #c0c0c0; 
      }
      span {
        color: #c0c0c0; 
        height: 1.25rem;
        margin-top: -2px;
        margin-right: 6px; 
      }
    } 

    .tab-disabled{
      @apply inline-flex p-4 text-gray-400 rounded-t-lg cursor-not-allowed dark:text-gray-500;
      svg{ 
        fill: #6d6e70;
      }
      span {
        color: #6d6e70; 
        height: 1.25rem;
        margin-top: -2px;
        margin-right: 6px; 
      }
    }

    .tab-active {
      @apply   border-b-2 rounded-t-lg text-black font-bold dark:text-white  border-black  dark:border-red-700; 
      svg{ 
        fill: #e20f1a; 
      }
      span {
        color: #e20f1a;
        height: 1.25rem;
        margin-top: -2px;
        margin-right: 6px; 
      }
    }

//? ------ TOASTS --------

    //! error TOAST ...
    .toast-error {
      @apply w-auto z-9999 absolute bottom-0 rounded-md border-l-8  border-red-400  bg-red-50 p-4;
      h3{
        @apply text-sm font-medium text-red-800;
      }
      .body{
        @apply  mt-2 text-sm text-red-700;
      }
      button{
        @apply  inline-flex rounded-md bg-red-100 p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50;
      }
      svg {
        @apply text-red-600;
      }
     
    }


    .toast-info {
      @apply w-auto z-9999 absolute bottom-0 rounded-md border-l-8  border-blue-400  bg-blue-50 p-4;
      h3{
        @apply text-sm font-medium text-blue-800;
      }
      .body{
        @apply  mt-2 text-sm text-blue-700;
      }
      button{
        @apply  inline-flex rounded-md bg-blue-100 p-1.5 text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50;
      }
      svg {
        @apply text-blue-700;
      } 
    }


    .toast-success {
      @apply w-auto z-9999 absolute bottom-0 rounded-md border-l-8  border-green-400  bg-green-50 p-4;
      h3{
        @apply text-sm font-medium text-green-800;
      }
      .body{
        @apply  mt-2 text-sm text-green-700;
      }
      button{
        @apply  inline-flex rounded-md bg-green-100 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50;
      }
      svg {
        @apply text-green-700;
      } 
    }

    .toast-settings {
      @apply lg:w-100  w-screen-10 z-9999 absolute bottom-0 rounded-md border-l-8  border-gray-400  bg-gray-50 pt-4 p-0;
     
      .header {
        @apply w-full flex items-center justify-between  ;

        h3{
          @apply w-full text-base font-bold text-gray-800 uppercase  ;
        }
      }

      .body{
        @apply w-full mt-2 text-sm text-gray-700 ;

        legend{
          @apply text-sm font-semibold text-gray-800;
        }

        .disclaimer{
          @apply mb-2 first-letter:capitalize italic;
        }
        .label {
          @apply ml-4 h-8 first-letter:capitalize font-semibold;
        }

        input[type='text']{
          @apply lg:w-60  w-36;
        }

        .err{
          @apply text-red-600 mb-2 font-semibold;
        }

        .box{
          @apply p-2 border bg-gray-300 border-gray-400 rounded-md mb-3;
        }
      }

      button{
        @apply flex justify-center rounded-md bg-gray-400 p-2 text-center items-center text-base font-semibold text-white shadow-sm hover:bg-gray-600  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600;
      
        span.ico {
          @apply  text-white -mt-1 mr-2;
        }
      }
 
      svg {
        @apply text-gray-200;
      } 

      a{
        @apply text-gray-600 decoration-primary-300;
      }
    }
 

    //? ------ MODAL --------
    .modal-settings {
      @apply lg:w-144 w-screen-10   rounded-md border-l-8  border-gray-400  bg-gray-50 pt-4 p-0; 
      
      h3{
        @apply pl-2  w-full text-base font-bold text-gray-800 uppercase  ;
      }

      .body{
        @apply w-full   text-sm text-gray-700 ;

        fieldset{
          @apply  mx-2 py-2 lg:px-4 px-2 rounded-md border-1 border-gray-300;
        } 

        legend{
          @apply text-sm font-semibold text-gray-800;
        }

        input[type='text']{
          @apply lg:w-60  w-48;
        }

        .disclaimer{
          @apply mb-2 first-letter:capitalize italic;
        }
        .label {
          @apply ml-4 first-letter:capitalize  font-semibold;
        }

        .err{
          @apply text-red-600 mb-2 font-semibold;
        }

        .box.mandatory{
          @apply p-2 border  border-gray-500 rounded-md mb-3;
        }

        .box{
          @apply p-2 border bg-gray-200 border-gray-300 rounded-md mb-3;
        }
      }

      button{
        @apply flex justify-center rounded-md bg-gray-400 p-2 text-center items-center text-base font-semibold text-white shadow-sm hover:bg-gray-600  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600;
      
        span.ico {
          @apply  text-white -mt-1 mr-2;
        }
      }
 
      svg {
        @apply text-gray-200;
      } 

      a{
        @apply  text-gray-600 decoration-primary-300;
      }
    }


//? ------ BADGE --------
.badge {
  @apply flex flex-wrap items-center justify-center px-2 py-0.5 ml-3 text-xs font-medium text-gray-500 bg-gray-200 rounded dark:bg-gray-700 dark:text-gray-400;
}

.badge-priority-HIGHEST, .badge-priority-label-highest, .badge-priority-MASSIMA,  .badge-red, .badge-scope-PRODUCTION {
  @apply rounded-md pt-[0.35rem]  pb-[0.25rem]   px-2 whitespace-nowrap font-semibold  bg-red-700 text-white;
}
.badge-priority-HIGH, .badge-priority-label-high,  .badge-priority-ALTA,  .badge-red {
  @apply rounded-md pt-[0.35rem]  pb-[0.25rem]   px-2 whitespace-nowrap font-semibold  bg-red-500 text-white;
}
.badge-priority-MEDIUM, .badge-priority-label-medium,  .badge-priority-MEDIA,  .badge-yellow, .badge-scope-DIAGNOSTICS, .badge-status-PENDING{
  @apply rounded-md pt-[0.35rem]  pb-[0.25rem]  px-2 whitespace-nowrap font-semibold bg-yellow-300 text-gray-800 ;
}
.badge-priority-LOW, .badge-priority-label-low, .badge-priority-BASSA,  .badge-green, .badge-scope-TESTING{
  @apply rounded-md  pt-[0.35rem]  pb-[0.25rem] px-2 whitespace-nowrap font-semibold  bg-green-400 text-gray-600;  
}
.badge-priority-LOWEST, .badge-priority-label-lowest, .badge-priority-MINIMA,   .badge-gray, .badge-scope-DEVELOPMENT {
  @apply rounded-md pt-[0.35rem]  pb-[0.25rem]   px-2 whitespace-nowrap font-semibold  bg-gray-200  dark:bg-gray-300 text-gray-700;
}

.badge-blue {
  @apply rounded-md flex items-center  justify-center px-2 whitespace-nowrap text-base font-semibold  bg-blue-200  dark:bg-blue-300 text-blue-700;
}

.badge-grayer{
  @apply rounded-full min-w-10 p-1 whitespace-nowrap font-semibold  bg-gray-400 text-gray-700;
}

.badge-transparent{
  @apply rounded-md pt-[0.35rem]  pb-[0.25rem]   px-2 whitespace-nowrap border-1 border-gray-400 text-black dark:text-gray-300 dark:border-gray-500;
}
 
 
.badge-icon{
  @apply flex items-center justify-center gap-x-2  rounded-full px-2 whitespace-nowrap bg-gray-400 text-gray-700;
  .symbol {
    @apply text-3xl; 
  }
  span{
    @apply text-2xl ;
  }
}

.greenBullet {
  @apply rounded-full p-2 w-6 h-6 bg-green-600 ;
}

//? ------ images --------

div.roundRectangle {
  @apply h-60 w-60 bg-white rounded-full overflow-hidden;

  img {
    @apply  h-full w-full object-scale-down object-center; 
  } 
}

div.roundRectangle-40 {
  @apply h-40 w-40 bg-white rounded-full overflow-hidden;

  img {
    @apply  h-full w-full object-scale-down object-center; 
  } 
}

div.roundIco12 {
  @apply h-12 w-12 rounded-full overflow-hidden p-2;

  img {
    @apply  h-full w-full object-scale-down object-center; 
  } 
}

//? ------ WIZARD --------

 

.bigBtn {
  @apply h-24 p-4 rounded-lg font-bold text-gray-800  flex items-center justify-center hover:bg-green-400 ring-4 bg-green-300  ring-green-500 ;

  span.ico{
    @apply leading-4 cursor-pointer;
    font-size: 3.25rem;
  }
  span.btn-text {
    @apply text-2xl  w-auto ml-2 ;
  }
}

.bigBtnActive {
  @apply h-24 p-4 rounded-lg font-bold text-gray-800 flex items-center justify-center hover:bg-green-400 ring-4 bg-green-300  ring-green-500 ;

  span.ico{
    @apply leading-4 cursor-pointer text-yellow-400;
    font-size: 3.25rem;
  }
  span.btn-text {
    @apply text-2xl  w-auto ml-2 ;
  }
}

.bigBtnActive:disabled{
  @apply h-24 p-4 rounded-lg font-bold text-gray-800 flex items-center justify-center ring-4 bg-gray-300  ring-gray-500 ; 
}

 








//? infoWindow (gmap popup)
.gm-style .gm-style-iw-c {
  padding: 0 ;
  margin: 0;
  background-color: #000000;
  -webkit-filter: none;
}

.gm-style .gm-style-iw-tc{
  -webkit-filter: none;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece{
  background-color: #000000;
}

.gm-style .gm-style-iw-tc::after {
  background-color: #000000;
  -webkit-filter: none;
  top: 1px;
  height: 18px;
}


.gm-ui-hover-effect {
  display: none !important;
}


//? ------ EFFETTI --------

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}
.blink {
  animation: blink 1s linear infinite;
}



//? ------ CUSTOM SPINNERs --------
.commandsCustomSpinner { 
  @apply max-w-[1.5rem] ;
}




//? ------ FULL CALENDAR --------
:root {
  --fc-list-event-dot-width: 10px;
  --fc-list-event-hover-bg-color: inherit ;  //#f5f5f5;
  
  --fc-daygrid-event-dot-width: 8px;
  
  --fc-small-font-size: .85em;
  --fc-page-bg-color: #7f868c;
  --fc-neutral-bg-color: hsla(0, 0%, 82%, .3);
  --fc-neutral-text-color: grey;
  --fc-border-color:  grey; // #ddd;
  --fc-button-text-color: #fff;
  --fc-button-bg-color: #2c3e50;
  --fc-button-border-color: #2c3e50;
  --fc-button-hover-bg-color: #1e2b37;
  --fc-button-hover-border-color: #1a252f;
  --fc-button-active-bg-color: #1a252f;
  --fc-button-active-border-color: #151e27;
  --fc-event-bg-color: #158748;
  --fc-event-border-color: #052712;
  --fc-event-text-color: #000;
  --fc-event-selected-overlay-color: rgba(0, 0, 0, .25);
  --fc-more-link-bg-color: #d0d0d0;
  --fc-more-link-text-color: inherit;
  --fc-event-resizer-thickness: 8px;
  --fc-event-resizer-dot-total-width: 8px;
  --fc-event-resizer-dot-border-width: 1px;
  --fc-non-business-color: hsla(0, 0%, 84%, .3);
  --fc-bg-event-color: #8fdf82;
  --fc-bg-event-opacity: 0.3;
  --fc-highlight-color: rgba(188, 232, 241, .3); 
  --fc-today-bg-color: rgba(122, 127, 120, 0.082);
  --fc-now-indicator-color: red; 

  --fc-daygrid-event-dot-width: 48px;
  
}

.fc .fc-scrollgrid-liquid{
  border-radius: 0.5rem!important; 
}


// DAY VIEW

.fc-timeGridDay-view {
  .fc-scrollgrid-liquid { 
    padding: 3px !important; 
  }

  .fc-theme-standard th {
    border: 10px solid var(--fc-border-color);  
  }

  .fc-timegrid-slot-minor {
    // border-top-style: dotted!important;
    // border-color: rgb(177, 174, 174)!important;  
    border-top: 4px dotted rgb(177, 174, 174)!important; 
  }

  .fc-scrollgrid thead tr th .fc-scroller-harness {
    .fc-scroller{
      overflow: hidden!important;
    }
  }

  .fc-timegrid-slots{ 
    table tbody>:not([hidden])~:not([hidden]) { 
      @apply border-gray-500;
    }  
  }

  td,  th  {
    padding: 6px; 
  }

}


//...........


// .fc-theme-standard .fc-list {
//   border: none; 
// }

// .fc-list {
//     border: none; 
// }


// MONTH VIEW ................

.fc-dayGridMonth-view{
  .fc-scrollgrid-liquid { 
    padding-right: 4px !important;
  }
} 

.fc-daygrid-event {
  border-radius: 3px;
  font-size: var(--fc-small-font-size);
  position: relative;
  white-space: wrap;   
  padding: 0; 
  @apply border-1 border-black  ;

  b.ng-star-inserted  { 
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 20px;
    @apply    px-2 mr-2;  
  }

  i.ng-star-inserted {
    @apply first-letter:capitalize;   
    font-weight: bolder;
  }
}




// DAY VIEW 

.fc-timegrid-event { 
  margin-top: -1px;
  margin-bottom: -1px;

  b.ng-star-inserted { 
    border-radius: 3px; 
    @apply text-blue-600  px-2 mr-2;  
  } 

  i.ng-star-inserted {  
    font-weight: bolder;
  }
 
}

.fc-list-day-text , .fc-list-day-side-text{
  @apply text-black;
}



  
// EVENTS
.greenEvent, .greenEvent:hover {
  background-color: #00FF00;     
  cursor: pointer;

  i,b, .fc-list-event-title, .fc-list-event-time {  
    font-weight: bolder; 
    @apply first-letter:capitalize text-black;   
  } 
}

.redEvent, .redEvent:hover {
  background-color: #FF0000;  
  cursor: pointer;

  i,b, .fc-list-event-title, .fc-list-event-time {  
    font-weight: bolder; 
    @apply first-letter:capitalize text-white;   
  } 
}

.blueEvent, .blueEvent:hover  {
  background-color: #0000FF; 
  cursor: pointer;

  i,b, .fc-list-event-title, .fc-list-event-time {  
    font-weight: bolder; 
    @apply first-letter:capitalize  text-white;  
  } 
}

.yellowEvent, .yellowEvent:hover {
  background-color: #FFFF00;    
  cursor: pointer;

  i,b, .fc-list-event-title, .fc-list-event-time {  
    font-weight: bolder; 
    @apply first-letter:capitalize text-black;   
  } 
} 

.orangeEvent, .orangeEvent:hover {
  background-color: #FFA500;  
  cursor: pointer;

  i,b, .fc-list-event-title, .fc-list-event-time {  
    font-weight: bolder; 
    @apply first-letter:capitalize text-black;   
  } 
}

.fc-timegrid-event b.ng-star-inserted {
  @apply text-black px-4 rounded-lg bg-white ;  
}



  //***** AGENDA VIEW  **********************
 .fc-listWeek-view {
    &.fc-list{  
      border: none!important; 
    }   
 } 


 //***** WEEK  VIEW **********************
 .fc-timeGridWeek-view{
  table.fc-scrollgrid-liquid {
    border-radius: .5rem !important;

    td,  th  {
      padding: 6px!important;  
    }

    th .fc-scroller{
      overflow: hidden!important;    
    }

    tbody > tr.fc-scrollgrid-section-body > td {
      div.fc-scroller{
        overflow: hidden!important;    
      } 

      div.fc-scroller-liquid-absolute{
        overflow: hidden scroll!important;
      }
    } 

    .fc-timegrid-event .fc-event-main {
      display: grid;
      height: fit-content; 
      box-sizing: border-box!important; 
    

      b.ng-star-inserted{
        width: fit-content;
        margin-bottom: 3px!important; 
      }


      i.ng-star-inserted{ 
        width: 98%;
        overflow: hidden;
        white-space: nowrap!important;
        text-overflow: ellipsis!important;  
        line-height: 8px!important;
      }
    }

 
  }
 }

// .fc-theme-standard td, .fc-theme-standard th {
//   // @apply border-4 ;
// }




// per disattivatre l'auto complete via css 
// MEGLIO disattivato a livello di template input field mediante autocomplete="off"
// input:-webkit-autofill,
// input:-webkit-autofill:hover, 
// input:-webkit-autofill:focus, 
// input:-webkit-autofill:active  {
//     // transition: background-color 5000s ease-in-out 0s;
//     // -webkit-text-fill-color: inherit !important;

//     transition: none;
//     -webkit-text-fill-color: none;
// }

//***** CHARTS  ********************** 

 
.btn-close-chart{
  @apply cursor-pointer h-6 w-6 text-gray-600 dark:text-gray-300  border-1 border-gray-400 bg-gray-300  
  hover:border-gray-800 hover:text-gray-800 dark:bg-gray-600 dark:hover:border-gray-200 dark:hover:text-gray-200 
  rounded-md text-sm py-0 px-1 flex justify-center items-center;  
}


h3.chartTitle { 
  font-size: 20px;
  font-weight: bold;  
  @apply  text-gray-400 dark:text-white first-letter:capitalize;
}